html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.big-clicker {
  position: absolute;
  inset: 0 0 0 0;
}

ul {
  padding-left: 20px;
}

.spaced {
  line-height: 190%;
}

.large {
  font-size: 24px;
  line-height: 120%;
}

.grey {
  color: #757575;
  line-height: 150%;
}

.light {
  font-weight: 400;
  font-family: var(--font-family-secondary);
}

.copy {
  font-weight: 400;
  font-size: 12px;
  font-family: var(--font-family-secondary);
}

a {
  transition: color 0.2s ease-in-out;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 190%;
  //font-size: 12px;
  &:hover {
    color: var(--color-primary) !important;
  }
}

//Use full
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.justify-left {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: flex-end;
}
.align-top {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-bottom {
  align-items: flex-end;
}

@include mqx(lg) {
  .text-large-left {
    text-align: left;
  }
  .text-large-center {
    text-align: center;
  }
  .text-large-right {
    text-align: right;
  }
}

@include mq(md) {
  .text-small-left {
    text-align: left;
  }
  .text-small-center {
    text-align: center;
  }
  .text-small-right {
    text-align: right;
  }
}

@include mq(s) {
  .text-extra-small-left {
    text-align: left;
  }
  .text-extra-small-center-extra-small {
    text-align: center;
  }
  .text-extra-small-right {
    text-align: right;
  }
}

///Inputs

.labelIcon {
  display: inline-flex;
  width: 42px;
  height: 42px;
  svg {
    scale: 2;
    path {
      fill: #c99b5f;
    }
  }
}

#map {
  position: relative;
  z-index: 0;
}

#homeImage img {
  margin-top: -80px;
  margin-bottom: -80px;
}

@media only screen and (max-width: 1200px) {
  #map [class*="Section_content"] {
    padding: 0;
  }
}

@media only screen and (max-width: 1060px) {
  #map [class*="Section_content"] {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  #homeImage img {
    height: 300px !important;
  }
}

@media only screen and (max-width: 1000px) {
  #quotes > div:last-child {
    padding: 80px 0 !important;

    [class*="Section_content"] {
      padding-left: 10px !important;
      padding-right: 0 !important;
    }
  }
}
@media only screen and (max-width: 730px) {
  #contactSection > div:last-child {
    padding-bottom: 0 !important;
  }

  #homeImage > div:last-child {
    padding: 0 0 20px !important;
  }

  #steps > div:last-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #homeSection > div:last-child {
    padding: 150px 0 !important;
  }
}

:root {
  --toastify-color-success: #080808;
  --toastify-icon-color-success: #fff;
  --toastify-text-color-success: #fff;
}

.Toastify__toast-container.Toastify__toast-container--top-center {
  width: 100%;
  max-width: 530px;
  .Toastify__toast-theme--colored.Toastify__toast--success {
    --toastify-color-success: #080808;
    --toastify-icon-color-success: #fff;
    --toastify-text-color-success: #fff;
    border-radius: 8px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
  }

  .Toastify__toast-icon {
    span {
      height: fit-content;
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .Toastify__close-button {
    align-self: unset;
    height: 16px;
    width: 16px;
    svg {
      height: 16px;
      width: 16px;
    }
  }

  .Toastify__toast-body {
    padding: 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    gap: 20px;
    margin: 0;

    * {
      font-family: "Montserrat";
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Toastify__toast-container.Toastify__toast-container--top-center {
    max-width: 100%;
    top: 0;
    padding: 0;
    left: 0;
    right: 0;
    transform: unset;
    .Toastify__toast-theme--colored.Toastify__toast--success {
      border-radius: 0;
    }
  }
}

.fade-enter {
  opacity: 0;
  will-change: opacity;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
  will-change: opacity;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.loading {
  opacity: 0;
  will-change: opacity;
}
main {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
