.IconCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    color: var(--card-icon-icon-c);
    font-weight: bold;
    svg {
      min-width: var(--card-icon-icon-size);
      min-height: var(--card-icon-icon-size);
    }
  }
}

@include el(
  ".IconCard .title",
  (
    "f": "@font",
    "fw": 300,
    "fs": 24px,
    "lh": 1.3,
    "mb": 16px,
    "mt": 25px,
  ),
  ()
);

@include el(
  ".IconCard .excerpt",
  (
    "f": "@font",
    "fw": 400,
    "fs": 14px,
    "lh": 2.1,
    "ls": 0.01em,
  ),
  ()
);
