.lnf-item-grid {
}

.item-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.item-grid.justify-left {
  justify-content: flex-start;
}
.item-grid.justify-center {
  justify-content: center;
}
.item-grid.justify-right {
  justify-content: flex-end;
}

.lnf-grid-item {
  /*flex-grow: 1;*/
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
}
.lnf-grid-item > * {
  width: 100%;
}

.top-area {
  padding: 0 0 40px 0;
}
.top-area h6 {
  margin: 0 0 28px;
}
.top-area h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #0a0a0a;
  margin: 0;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.bottom-area {
  display: flex;
  justify-content: flex-end;
  padding: 40px 0 0;
}
.bottom-area a {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #0a0a0a;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bottom-area a span {
  display: inline-block;
  margin-right: 18px;
}

.lnf-item-grid {
  --grid-gap: 20px;
  --grid-item-width: 1;
}

@media only screen and (min-width: 1200px) {
  .lnf-item-grid-large-12 .lnf-grid-item {
    width: calc(100% / 12 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-11 .lnf-grid-item {
    width: calc(100% / 11 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-10 .lnf-grid-item {
    width: calc(100% / 10 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-9 .lnf-grid-item {
    width: calc(100% / 9 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-8 .lnf-grid-item {
    width: calc(100% / 8 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-7 .lnf-grid-item {
    width: calc(100% / 7 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-6 .lnf-grid-item {
    width: calc(100% / 6 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-5 .lnf-grid-item {
    width: calc(100% / 5 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-4 .lnf-grid-item {
    width: calc(100% / 4 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-3 .lnf-grid-item {
    width: calc(100% / 3 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-2 .lnf-grid-item {
    width: calc(100% / 2 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-large-1 .lnf-grid-item {
    width: calc(100% / 1 - var(--grid-gap)) !important;
  }

  .item-grid.justify-large-left {
    justify-content: flex-start !important;
  }
  .item-grid.justify-large-center {
    justify-content: center !important;
  }
  .item-grid.justify-large-right {
    justify-content: flex-end !important;
  }

  .item-grid.justify-large-left .lnf-grid-item {
    justify-content: flex-start !important;
  }
  .item-grid.justify-large-center .lnf-grid-item {
    justify-content: center !important;
  }
  .item-grid.justify-large-right .lnf-grid-item {
    justify-content: flex-end !important;
  }

  .item-grid.align-large-top {
    align-items: flex-start !important;
  }
  .item-grid.align-large-center {
    align-items: center !important;
  }
  .item-grid.align-large-bottom {
    align-items: flex-end !important;
  }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1000px) {
  .lnf-item-grid-small-12 .lnf-grid-item {
    width: calc(100% / 12 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-11 .lnf-grid-item {
    width: calc(100% / 11 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-10 .lnf-grid-item {
    width: calc(100% / 10 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-9 .lnf-grid-item {
    width: calc(100% / 9 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-8 .lnf-grid-item {
    width: calc(100% / 8 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-7 .lnf-grid-item {
    width: calc(100% / 7 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-6 .lnf-grid-item {
    width: calc(100% / 6 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-5 .lnf-grid-item {
    width: calc(100% / 5 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-4 .lnf-grid-item {
    width: calc(100% / 4 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-3 .lnf-grid-item {
    width: calc(100% / 3 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-2 .lnf-grid-item {
    width: calc(100% / 2 - var(--grid-gap)) !important;
  }
  .lnf-item-grid-small-1 .lnf-grid-item {
    width: calc(100% / 1 - var(--grid-gap)) !important;
  }

  .item-grid.justify-small-left {
    justify-content: flex-start !important;
  }
  .item-grid.justify-small-center {
    justify-content: center !important;
  }
  .item-grid.justify-small-right {
    justify-content: flex-end !important;
  }

  .item-grid.justify-small-left .lnf-grid-item {
    justify-content: flex-start !important;
  }
  .item-grid.justify-small-center .lnf-grid-item {
    justify-content: center !important;
  }
  .item-grid.justify-small-right .lnf-grid-item {
    justify-content: flex-end !important;
  }

  .item-grid.align-small-top {
    align-items: flex-start !important;
  }
  .item-grid.align-small-center {
    align-items: center !important;
  }
  .item-grid.align-small-bottom {
    align-items: flex-end !important;
  }
}

@media only screen and (max-width: 650px) {
  .lnf-item-grid-extra-small-12 .lnf-grid-item {
    width: calc(
      100% / 12 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .lnf-item-grid-extra-small-11 .lnf-grid-item {
    width: calc(
      100% / 11 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .lnf-item-grid-extra-small-10 .lnf-grid-item {
    width: calc(
      100% / 10 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .lnf-item-grid-extra-small-9 .lnf-grid-item {
    width: calc(100% / 9 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-8 .lnf-grid-item {
    width: calc(100% / 8 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-7 .lnf-grid-item {
    width: calc(100% / 7 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-6 .lnf-grid-item {
    width: calc(100% / 6 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-5 .lnf-grid-item {
    width: calc(100% / 5 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-4 .lnf-grid-item {
    width: calc(100% / 4 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-3 .lnf-grid-item {
    width: calc(100% / 3 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-2 .lnf-grid-item {
    width: calc(100% / 2 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .lnf-item-grid-extra-small-1 .lnf-grid-item {
    width: calc(100% / 1 - var(--grid-gap)) !important;
  }

  .item-grid.justify-extra-small-left {
    justify-content: flex-start !important;
  }
  .item-grid.justify-extra-small-center {
    justify-content: center !important;
  }
  .item-grid.justify-extra-small-right {
    justify-content: flex-end !important;
  }

  .item-grid.justify-extra-small-left .lnf-grid-item {
    justify-content: flex-start !important;
  }
  .item-grid.justify-extra-small-center .lnf-grid-item {
    justify-content: center !important;
  }
  .item-grid.justify-extra-small-right .lnf-grid-item {
    justify-content: flex-end !important;
  }

  .item-grid.align-extra-small-top {
    align-items: flex-start !important;
  }
  .item-grid.align-extra-small-center {
    align-items: center !important;
  }
  .item-grid.align-extra-small-bottom {
    align-items: flex-end !important;
  }
}
