.inputWrapper {
  border-style: solid;
  border-color: var(--input-border);
  border-width: var(--input-border-width);
  color: var(--input-text);
  background-color: var(--input-bg);
  padding: var(--input-pd);
  border-radius: var(--input-radius);

  display: inline-block;
  line-height: 1;
  position: relative;
  transition: 0.2s ease all;
  box-sizing: border-box;
  // height: 40px;
  width: 100%;

  .input {
    background-color: transparent;
    height: 100%;
    font-size: 16px;
    width: 100%;
    border: none;
    outline: none;
    line-height: 0;
    padding: 0;
    font-weight: 400;
  }

  .label {
    color: var(--input-label);

    position: absolute;
    pointer-events: none;
    top: calc(100% + 10px);
    left: 0px;
    // transform: translateY(-50%);
    transition: 0.2s ease all;
    font-weight: 500;
    font-size: 14px;

    .input-error {
      position: absolute;
      bottom: -30px;
      left: 0;
      color: var(--input-label-error);
    }
  }

  input {
    color: inherit;
    height: 100% !important;
    line-height: 100% !important;
    min-height: 20px;
  }
  &.hover,
  &.focus,
  &.filled {
    // margin-top: 28px;

    border-color: var(--input-border-filled);
    border-width: var(--input-border-width-filled);
    .label {
      color: var(--input-label);

      // top: calc(-16px - 10px);
      // left: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.02em;
    }
  }
  &[data-disabled="true"] {
    border: var(--input-border-disabled);

    .label {
      color: var(--input-label-disabled) !important;
    }
  }

  &.failed {
    border: var(--input-border-error);
    margin-bottom: 30px;

    .label {
      color: var(--input-label-error);
    }
  }
}

.inputWrapperCheckbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;

  .check {
    background-color: transparent;
    transition: all 0.2s ease;
    box-sizing: border-box;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid var(--input-border);
    svg {
      color: transparent;
      transition: all 0.2s ease;
    }
  }

  &[data-radio] {
    .check {
      border-width: 2px;
      border-radius: 50%;
    }
  }

  &[data-checked="true"] {
    .check {
      border-color: var(--primary);
      background-color: var(--primary);
      svg {
        color: white;
      }
    }

    &[data-radio] {
      .check {
        background-color: transparent;
        svg {
          color: var(--primary);
        }
      }
    }
  }

  label {
    font-size: 18px;
    line-height: 200%;
    margin-left: 16px;
  }

  &[data-disabled="true"] {
    .check {
      border-color: #999999;
      cursor: not-allowed;
    }

    label {
      color: #999999;
    }
    &[data-checked="true"] {
      .check {
        background-color: #999999;
        svg {
          color: white;
        }
      }
    }
  }
}
