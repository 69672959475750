@import "mixins/mixins.scss";
@import "functions.scss";

@import "./fieldTextArea";
@import "./fieldText";
@import "./fieldSelect";
@import "./itemGrid.scss";

@import "../public/fonts/Poppins.css";
@import "../public/fonts/SpaceMono.css";
@import "variables.scss";
@import "components.scss";
@import "misc.scss";

* {
  box-sizing: border-box;
  font-family: var(--font);
}

@import "./typography.scss";
@import "./lnffixes.scss";

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@include el(
  ".themedButton",
  (
    "lh": 2,
    "f": "@font",
    "fs": 14px,
    "fw": 600,
  ),
  ()
);
.themedButton {
  --button-tone: var(--current-button-tone, var(--black));
  --button-contrast: var(--current-button-contrast, var(--white));
  --button-accent: var(--current-button-primary, var(--primary));
  --button-accent-inverse: var(--current-button-accent, var(--primary));

  --buttonOutlineColor: var(--button-contrast);
  --buttonOutlineWidth: 0px;
  --buttonBorder: var(--button-contrast);
  --buttonText: var(--button-contrast);
  --buttonIcon: var(--button-accent);
  --buttonBackground: transparent;

  --buttonScaleHover: 1;
  // --buttonScaleHover: 1.02;

  --buttonBorderWidth: 1px;
  --buttonInnerBorder: unset;
  --buttonInnerBorderWidth: 0;

  --buttonPaddingX: 24px;
  --buttonPaddingY: 10px;
  --buttonPaddingLeft: var(--buttonPaddingX);
  --buttonPaddingRight: var(--buttonPaddingX);
  --buttonPaddingTop: var(--buttonPaddingY);
  --buttonPaddingBottom: var(--buttonPaddingY);

  --buttonRadius: 100px;
  --buttonShadow: none;
  --buttonShadowHover: none;
  --buttonIconSize: 24px;
  --buttonWidth: unset;
  --buttonHeight: unset;
  --buttonBoxSizing: content-box;
  --buttonCursor: pointer;
  --buttonOpacity: 1;
  --buttonTextDislpay: inline-flex;
  --buttonTextDecoration: none;
  --buttonInnerMargin: 0px;

  &[data-style*="noOutline"] {
    --buttonBorderColorNoOutLine: transparent;

    --buttonBorder: var(--buttonBorderColorNoOutLine);
  }
  &[data-style*="thickOutline"] {
    // --buttonBorderWidthThick: 2px;
    --buttonOutlineWidthThick: 1px;

    // --buttonBorderWidth: var(--buttonBorderWidthThick);
    --buttonOutlineWidth: var(--buttonOutlineWidthThick);
  }

  &[data-style*="underline"] {
    --buttonTextDecorationUnderline: underline;

    --buttonTextDecoration: var(--buttonTextDecorationUnderline);
  }

  &[data-style*="bordered"] {
    --buttonBorderedWidth: 1px;
    --buttonBordered: var(--button-contrast);
    --buttonBorderedMargin: 1px;

    --buttonInnerBorder: var(--buttonBordered);
    --buttonInnerBorderWidth: var(--buttonBorderedWidth);
    --buttonInnerMargin: var(--buttonBorderedMargin);
  }
  &[data-style*="filled"] {
    --buttonFill: var(--button-contrast);
    --buttonFillText: var(--button-tone);
    --buttonFillIcon: var(--button-accent-inverse);

    --buttonBackground: var(--buttonFill);
    --buttonText: var(--buttonFillText);
    --buttonIcon: var(--buttonFillIcon);

    &[data-style*="bordered"] {
      --buttonBorderedFilled: var(--button-tone);
      --buttonBorderedFilledText: var(--button-tone);
      --buttonBorderedFilledMargin: 1px;
      --buttonBorderedFilledWidth: 1px;

      --buttonInnerBorder: var(--buttonBorderedFilled);
      --buttonInnerBorderWidth: var(--buttonBorderedFilledWidth);
      --buttonText: var(--buttonBorderedFilledText);
      --buttonInnerMargin: var(--buttonBorderedFilledMargin);
    }
  }
  &[data-style*="shade"] {
    --buttonShadowed: 0px 4px 10px rgba(0, 0, 0, 0.2);
    --buttonShadowedHover: 0px 4px 10px rgba(0, 0, 0, 0.4);

    --buttonShadow: var(--buttonShadowed);
    --buttonShadowHover: var(--buttonShadowedHover);
  }

  &[data-style*="leftIconed"] {
    --buttonPaddingXIconed: 20px;
    --buttonPaddingLeft: var(--buttonPaddingXIconed);
  }
  &[data-style*="rightIconed"] {
    --buttonPaddingXIconed: 20px;
    --buttonPaddingRight: var(--buttonPaddingXIconed);
  }

  &[data-style*="expandMobile"] {
    @include mq("md") {
      --buttonWidthFull: 100%;

      --buttonWidth: var(--buttonWidthFull);
    }
  }

  &[data-style*="noText"] {
    --buttonTextDislpayNone: none;

    --buttonTextDislpay: var(--buttonTextDislpayNone);
  }

  &[data-style*="circle"] {
    --buttonRadiusCircle: 50%;
    --buttonPaddingCircle: 10px;
    --buttonSizeCircle: 48px;
    --buttonBoxSizingCircle: border-box;

    --themedButton-lh: 0;

    --buttonWidth: var(--buttonSizeCircle);
    --buttonHeight: var(--buttonSizeCircle);
    --buttonRadius: var(--buttonRadiusCircle);
    --buttonPaddingLeft: var(--buttonPaddingCircle);
    --buttonPaddingRight: var(--buttonPaddingCircle);
    --buttonPaddingTop: var(--buttonPaddingCircle);
    --buttonPaddingBottom: var(--buttonPaddingCircle);
    --buttonBoxSizing: var(--buttonBoxSizingCircle);
  }

  &[data-style*="disabled"] {
    --buttonDisabledOpacity: 0.3;
    --buttonScaleHoverDisabled: 1;
    // --buttonScaleHoverDisabled: 0.95;
    --buttonCursorDisabled: not-allowed;

    --buttonScaleHover: var(--buttonScaleHoverDisabled);
    --buttonCursor: var(--buttonCursorDisabled);
    --buttonOpacity: var(--buttonDisabledOpacity);
    --buttonScaleHover: var(--buttonScaleHoverDisabled);
  }

  &[data-style*="expandMobile"] {
    // --buttonWidth: 100%;
    @include mq(tablet) {
      --buttonWidth: 100%;
    }
  }
}

.themedButton {
  transition: all 0.2s ease-in-out, color 0s ease, border-color 0.2s ease-in-out;
  border-style: solid;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-style: solid;

  cursor: var(--buttonCursor);
  background-color: var(--buttonBackground);
  border-width: var(--buttonBorderWidth);
  border-color: var(--buttonBorder);
  border-radius: var(--buttonRadius);
  box-shadow: var(--buttonShadow);
  color: var(--buttonText);
  opacity: var(--buttonOpacity);
  overflow: hidden;
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  outline-width: var(--buttonOutlineWidth);
  outline-color: var(--buttonOutlineColor);

  .buttonInner {
    transition: all 0.2s ease-in-out, color 0s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    gap: 12px;
    width: 100%;
    box-sizing: border-box;

    cursor: var(--buttonCursor);

    border-radius: var(--buttonRadius);
    box-sizing: var(--buttonBoxSizing);
    min-width: var(--buttonWidth);
    height: var(--buttonHeight);
    min-height: var(--buttonHeight);
    padding-top: var(--buttonPaddingTop);
    padding-bottom: var(--buttonPaddingBottom);
    padding-left: var(--buttonPaddingLeft);
    padding-right: var(--buttonPaddingRight);
    border-width: var(--buttonInnerBorderWidth);
    border-color: var(--buttonInnerBorder);
    margin: var(--buttonInnerMargin);

    span {
      display: var(--buttonTextDislpay);
      text-decoration: var(--buttonTextDecoration);
      transition: color 0s;
      cursor: var(--buttonCursor);
    }

    .buttonIcon {
      transition: all 0.2s ease-in-out;
      cursor: var(--buttonCursor);

      color: var(--buttonIcon);
      display: flex;
      svg {
        width: var(--buttonIconSize);
        height: var(--buttonIconSize);
      }
    }
  }

  &:hover {
    scale: var(--buttonScaleHover);
    box-shadow: var(--buttonShadowHover);
  }
}

.blogPage {
  h1 {
    margin-bottom: 24px;
  }
}
