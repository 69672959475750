.BlogCard {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  animation: 0.5s fadeIn forwards;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: flex-start;
  flex-direction: column;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border: 2px solid var(--blog-bg);

  min-width: min(320px, 100%);

  border-radius: var(--blog-radius);
  background: var(--blog-bg);

  .BlogImage {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    overflow: hidden;
    img {
      object-fit: cover;
      object-position: 50% 50%;
      aspect-ratio: 8 / 5;
      width: 100%;
      transition: all 0.2s ease-in-out;
    }
  }

  .BlogContent {
    padding: var(--blog-pd);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-self: stretch;
    flex: 3;
    color: var(--blog-text);

    .title {
      color: var(--blog-text);
    }

    .info {
      display: flex;
      gap: 5px;
      align-items: center;

      .timeToRead {
      }
    }

    p {
      @include line-clamp(2);
    }

    .bigClicker {
      position: absolute;
      inset: 0 0 0 0;
      z-index: 3;
      cursor: pointer;
    }
  }

  &:hover {
    scale: 1;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    .BlogImage {
      img {
        scale: 1.05;
      }
    }
  }

  &:focus,
  &:active {
    border: 2px solid var(--primary-primary-blue, #4070ff);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
