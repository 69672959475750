@mixin truncate($width: 100%) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin line-clamp($lineCount: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
