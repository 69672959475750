:root {
  @include let("font", "Poppins");
  // @include let("font-secondary", "Space Mono");
  @include let("font-secondary", "Poppins");
  // @include color("test", #4B5FEB);
  --gradient-blue-spiral: conic-gradient(
    from -90deg at 50% 50%,
    #3264fa 0deg,
    rgba(95, 255, 226, 0) 360deg
  );
  @include let(
    "gradient-blue-old",
    linear-gradient(98.98deg, #4070ff 30.61%, #5fffe2 165.64%)
  );
  @include let(
    "gradient-blue",
    linear-gradient(98.98deg, #4070ff 30.61%, #5fffe2 58.52%)
  );
  @include let(
    "gradient-black",
    linear-gradient(90deg, #121212 0%, #3a3a39 100%)
  );
  @include lets(
    "br",
    (
      s: 8px,
      m: 8px,
      l: 16px,
      circle: 50%,
    )
  );

  @include lets(
    "fw",
    (
      s: 400,
      m: 500,
      l: 700,
    )
  );

  @include lets(
    "fs",
    //TODO font sizes here
    (s: 400, m: 500, l: 700)
  );

  @include colors(
    (
      "white": #fff,
      "black": #000,
      "white-off": #f5f5f5,
      "charcoal": #4b4b4b,
      "blue": #4070ff,
      "sky-blue": #3198ff,
      "powder-blue": #98cdff,
      "faint-blue": #e1e1fa,
      "yellow": #e7eea0,
    )
  );

  @include vars(
    (
      "primary": "@blue",
      "primaryGradient": "@gradient-blue-spiral",
      "secondaryGradient": "@gradient-blue",
      "secondary": "@sky-blue",
      "tertiaryGradient": "@gradient-black",
      "tertiary": "@powder-blue",
      "disabled": "@charcoal",
      "background": "@white-off",
    )
  );

  @include theme("black", "@black", "@black-contrast");
  @include theme("white", "@white", "@white-contrast");
  @include theme("white-off", "@white-off", "@white-off-contrast");
  @include theme("charcoal", "@charcoal", "@charcoal-contrast");
  @include theme("primary", "@primary", "@blue-contrast");
  @include theme("yellow", "@yellow", "@black");
  @include theme("warning", "@yellow", "@black");

  @include theme("secondary", "@secondary", "@sky-blue-contrast");
  @include theme("tertiary", "@tertiary", "@powder-blue-contrast");
  @include themeGradient(
    "primaryGradient",
    "@secondary",
    "@primaryGradient",
    "@blue-contrast"
  );
  @include themeGradient(
    "secondaryGradient",
    "@secondary",
    "@secondaryGradient",
    "@sky-blue-contrast",
    "@charcoal"
  );
  @include themeGradient(
    "tertiaryGradient",
    "@tertiary",
    "@tertiaryGradient",
    "@sky-blue-contrast"
  );

  // @include theme("background", "@background", "@black-contrast");
}
[data-theme] {
  --current-bg-color: inherit;
  --current-text-color: #000;
  background: var(--current-bg-color);
  color: var(--current-text-color);
}

[data-theme="primaryGradient"] {
  // background-image: url("https://a.storyblok.com/f/235274/1728x1117/d62807ce94/graphic.svg") !important;
  background-color: #76c5f7;
  position: relative;
  &:before {
    content: "";
    @include full;
    // background-color: #76c5f7;

    // background-image: conic-gradient(
    //   from 90deg at 50% 50%,
    //   #3264fa 0deg,
    //   rgba(95, 255, 226, 0) 360deg
    // );
    // box-shadow: inset 0px 0px 120px 80px #98cdff;
    // transform: matrix(-1, 0, 0, 1, 0, 0);
    // width: 200vw;
    // height: 200vh;
    // left: calc(-80vw);
    // top: calc(-40vh);
  }
  &:after {
    content: "";
    @include full;

    // position: absolute;
    // width: 3397px;
    // height: 3397px;
    // left: calc(-140% - 3397px / 2 + 2861.5px);
    // top: calc(50% - 3397px / 2 - 0px);
    // background: conic-gradient(
    //   from 90deg at 50% 50%,
    //   #3264fa 0deg,
    //   rgba(95, 255, 226, 0) 360deg
    // );
    // box-shadow: inset 0px 0px 120px 80px #98cdff;
    // transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

[class*="gradient-text"] {
  background: var(--current-text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@include el(
  h1,
  (
    "f": "@font",
    "fw": 600,
    "fc": "normal",
    "fd": "none",
    "ls": -0.05em,
    "lh": "1",
    "fs": 100px,
    "mb": 126px,
    "mt": 0,
  ),
  (
    "fs": 40px,
    fw: 550,
    "mb": 40px,
  )
);

h1:last-child {
  margin-bottom: 0;
}

@include el(
  h2,
  (
    "f": "@font",
    "fw": 500,
    "fc": normal,
    "fd": none,
    "ls": -0.03em,
    "lh": 1.2,
    "fs": 48px,
    "mb": 48px,
    mt: 0,
  ),
  (
    "fs": 32px,
    "mb": 32px,
  )
);

@include el(
  h3,
  (
    "f": "@font",
    "fw": "@b-b",
    "fc": "normal",
    "fd": "none",
    "lh": "1.3",
    "ls": -0.04em,
    "fs": 28px,
    "mb": 28px,
  ),
  (
    "fs": 24px,
    "mb": 24px,
    "lh": 1.4,
    "ls": -0.03em,
  )
);

@include el(
  h4,
  (
    "f": "@font",
    "fw": semibold,
    "fc": "normal",
    "fd": "none",
    "ls": 0,
    "lh": "1.4",
    "fs": 20px,
    "mb": 20px,
  ),
  ()
);

@include el(
  h5,
  (
    "f": "@font",
    "fw": semibold,
    "fc": "normal",
    "fd": "none",
    "ls": 0,
    "lh": "1.4",
    "fs": 20px,
    "mb": 20px,
  ),
  ()
);

@include el(
  h6,
  (
    "f": "@font",
    "fw": semibold,
    "fc": "normal",
    "fd": "none",
    "ls": 0,
    "lh": "1.4",
    "fs": 20px,
    "mb": 20px,
  ),
  ()
);

@include el(
  p,
  (
    "f": "@font",
    "fw": normal,
    "fs": 18px,
    "ls": 0,
    "lh": 2,
    "mb": 30px,
  ),
  ()
);
@include el(
  ul,
  (
    "f": "@font",
    "fw": normal,
    "fs": 18px,
    "ls": 0,
    "lh": 2,
    "mt": 0,
    "mb": 0,
  ),
  ()
);
li {
  font-weight: bold;
  &::marker {
    color: var(--primary);
  }
  p {
    margin: 0;
  }
}

@include el(
  label,
  (
    "f": "@font",
    "fw": 400,
    "fs": 18px,
    "ls": 0,
    "lh": 2,
    "mb": 0,
  ),
  ()
);

@include el(
  a,
  (
    "f": "@font",
  ),
  (
    "fs": 40px,
  )
);

@include el(
  b,
  (
    "fw": semibold,
  ),
  ()
);

.outlinedText {
  color: var(--current-text-color);
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--current-text-color);
}

@include el(
  ".headingTitle",
  (
    "fw": 600,
    "fs": 100px,
    "lh": 1,
    "ls": -0.05em,
    "mb": 52px,
  ),
  (
    "fs": 60px,
    "mb": 40px,
  )
);

@include el(
  ".light",
  (
    "fw": "b-l",
    "ls": 0,
    "mb": 24px,
  ),
  ()
);

@include el(
  ".overline",
  (
    "fw": semibold,
    "ft": uppercase,
    "fs": 12px,
    "lh": 1.5,
    "ls": 0.2em,
    "mb": 24px,
  ),
  ()
);

@include el(
  ".small",
  (
    "fs": 14px,
    "ls": 0,
    "lh": 2,
    "mb": 14px,
  ),
  ()
);
@include el(
  ".tinyText",
  (
    "fs": 12px,
    "ls": 0,
    "lh": 1.9,
    "mb": 12px,
  ),
  ()
);

@include el(
  ".mono",
  (
    "f": "@font-secondary",
    "fw": 400,
    "fs": 22px,
    "ls": 0,
    "lh": 1.8,
    "mb": 22px,
  ),
  (
    "fs": 16px,
    "mb": 16px,
  )
);

@include el(
  ".mono.small",
  (
    "fw": 400,
    "fs": 18px,
    "ls": 0,
    "lh": 1.7,
    "mb": 18px,
  ),
  (
    "fs": 16px,
    "mb": 16px,
  )
);
@include el(
  ".thin",
  (
    "fw": 250,
  ),
  ()
);
@include el(
  ".thick",
  (
    "fw": 600,
  ),
  ()
);
@include el(
  ".backLink.themedButton .buttonInner",
  (
    "f": "@font-secondary",
    "fw": 700,
    "fs": 12px,
    "ls": 0.13em,
    "lh": 1.6,
    fd: underline,
    ft: uppercase,
    c: "var(--primary) !important",
  ),
  ()
);
@include el(
  "footer a",
  (
    "f": "@font",
    "fw": 400,
    "fs": 14px,
    "lh": 2,
  ),
  ()
);

@include el(
  "footer .footerLinks a",
  (
    "f": "@font",
    "fw": 500,
    "fs": 12px,
    "ls": 0.3em,
    "lh": 1.5,
    "mb": 12px,
    ft: uppercase,
  ),
  ()
);

.footerLinks a {
  position: relative;
  padding: 12px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    height: 1px;
    top: 100%;
    opacity: 0;
    background-color: var(--primary);
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &:focus,
  &:active {
    &::before {
      inset: 0;
      box-sizing: border-box;
      height: 100%;
      background-color: transparent;
      border: 2px solid var(--primary);
      border-radius: 8px;
      opacity: 1;
    }
  }
}

@include el(
  ".BlogCard .title",
  (
    "f": "@font",
    "fw": 500,
    "fs": 28px,
    "ls": -0.03em,
    "lh": 1.3,
    "mt": 32px,
    "mb": 0,
  ),
  ()
);

@include el(
  ".BlogCard .label",
  (
    "f": "@font",
    "fw": 600,
    "fs": 12px,
    "ls": 0.2em,
    "lh": 1.5,
    "mb": 12px,
    ft: uppercase,
    c: "@primary",
  ),
  ()
);
@include el(
  ".BlogCard .info",
  (
    "f": "@font",
    "fw": 400,
    "fs": 14px,
    "lh": 2,
    c: #3a3d4d,
  ),
  ()
);
@include el(
  ".BlogCard p",
  (
    "f": "@font",
    "fw": 400,
    "fs": 14px,
    "lh": 2,
    c: #252833,
    "mb": 12px,
  ),
  ()
);
@include el(
  ".footer .link",
  (
    "f": "@font",
    "fw": 500,
    "fs": 12px,
    "lh": 1.5,
    "ls": 0.3em,
    ft: uppercase,
  ),
  ()
);
:root {
  @include vars(
    (
      "container": 1336px,
      "container-pd": 40px,
      "space-tiny": 16px,
      "space-small": 52px,
      "space": 100px,
      "padding": 200px,
      "padding-huge": 400px,
      "padding-small": 100px,
      "padding-medium": 140px,
      "padding-large": 160px,
      "padding-article": 160px,
    )
  );

  @include mq(tablet) {
    @include vars(
      (
        "space": 52px,
        "padding": 80px,
        "space-tiny": 8px,
        "space-small": 32px,
        "padding-huge": 80px,
        "padding-small": 80px,
        "padding-medium": 80px,
        "padding-large": 80px,
        "padding-article": 300px,
      )
    );
  }
  @include vars(
    (
      "header-static-container": "@container",
      "header-static-pd": 24px 20px,
      "header-static-bg": transparent,

      "header-static-text": "@black",
      "header-static-logo-h": 24px,

      "header-sticky-container": "@container",
      "header-sticky-text": "@black",
      "header-sticky-bg": "@white-off",
      "header-sticky-pd": 16px 20px,
      "header-sticky-logo-h": 24px,

      "navigation-align": center,
      "navigation-items-gap": 52px,
      "navigation-item-color": "@black",
      "navigation-item-color-hover": "@primary",
      "navigation-": 34px,

      "navigation-mobile-bg": "@black",
      "navigation-mobile-text": "@white",
      "navigation-mobile-pd": 24px 44px 54px 44px,
      "navigation-mobile-items-gap": 52px,

      "cookiebanner-bg": "@yellow",
      "cookiebanner-container": "@container",
      "cookiebanner-container-pd": "@container-pd",
      "cookiebanner-pd": 20px,
      "cookiebanner-": 34px,

      "section-container": "@container",
      "section-container-pd": "@container-pd",
      "section-": 34px,

      "blog-bg": "@white-off",
      "blog-text": "@black",
      "blog-radius": 8px,
      "blog-pd": 40px 32px,
      "blog-": 34px,

      "card-icon-icon-c": "@primary",
      "card-icon-icon-size": 28px,

      "input-bg": transparent,
      "input-border": "@black",
      "input-border-width": 0 0 1px 0,
      "input-radius": 0,
      "input-pd": 10px 16px,
      "input-text": "@black",
      "input-label": "@black",
      "input-label-disabled": #a3a3a3,
      "input-border-disabled": 2px solid #a3a3a3,
      "input-border-error": 1px solid #980000,
      "input-label-error": 1px solid #980000,

      "input-border-filled": "@black",
      "input-border-width-filled": 2px,
    )
  );
}

:root [data-theme="tertiary"] {
  // --current-text-color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  & + .hideDesktop {
    @include mq(md) {
      margin-bottom: 0;
    }
  }

  @include mq(md) {
    & + .hideMobile {
      margin-bottom: 0;
    }
    margin-bottom: 0;
  }
}

main:not([data-editmode="true"]) {
  .hideMobile {
    @include mq(md) {
      display: none !important;
    }
  }

  .hideDesktop {
    @include mqx(md) {
      display: none !important;
    }
  }
}

main[data-editmode="true"] {
  a,
  button {
    pointer-events: none;
  }
  .hideMobile {
    @include mq(md) {
      position: relative;

      :after {
        position: absolute;
        content: "Hidden";
        inset: 0;
        background: #53535378;
        z-index: 2;
        color: white;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .hideDesktop {
    @include mqx(md) {
      position: relative;
      :after {
        position: absolute;
        content: "Hidden";
        inset: 0;
        background: #53535378;
        z-index: 2;
        color: white;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
#takecontrol {
  border-bottom: 1px solid var(--tertiary);
}
#calltoaction [data-theme="inherit"] {
  border-bottom: 1px solid var(--tertiary);
}

.cycling-text-container {
  @include mq(xs) {
    .cycling-text {
      display: block;
    }
  }
  .append {
    white-space: nowrap;
    @include mq(xs) {
      display: block;

      white-space: normal;
    }
  }
}
