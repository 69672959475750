.lnf-list {
  --list-icon-color: black;
  --list-icon-content: "-";
}
.lnf-list-list {
  display: flex;
  flex-direction: column;
}

.lnf-list-list li {
}

.lnf-list li::marker,
.lnf-list li::before {
  color: var(--list-icon-color);
}

.lnf-list-heading-true li:first-child {
  font-weight: bold;
}

.lnf-list-list.Numbered li {
  list-style: decimal;
}
.lnf-list-list.Dots li {
  list-style: disc;
}
.lnf-list-list.Squares li {
  list-style: square;
}
.lnf-list-list.Georgian li {
  list-style: georgian;
}
.lnf-list-list.Chinese li {
  list-style: -moz-simp-chinese-formal;
}
.lnf-list-list.Kannada li {
  list-style: -moz-kannada;
}
.lnf-list-list.Bullet li:before {
  content: "•";
  margin-right: 10px;
}
.lnf-list-list.Dash li:before {
  content: "-";
  margin-right: 10px;
}
.lnf-list-list.Check li:before,
.lnf-list-list.Tick li:before {
  content: "✓";
  margin-right: 10px;
}
.lnf-list-list.Cross li:before {
  content: "✕";
  margin-right: 10px;
}

.lnf-list-list.Circle li {
  list-style: circle;
}

.lnf-list-list.None,
.lnf-list-list.Bullet,
.lnf-list-list.Icon,
.lnf-list-list.Dash,
.lnf-list-list.Check,
.lnf-list-list.Cross {
  padding-left: 10px;
}

.lnf-list-list.None li,
.lnf-list-list.Bullet li,
.lnf-list-list.Dash li,
.lnf-list-list.Check li,
.lnf-list-list.Cross li {
  list-style: none;
  display: inline-flex;
}
.lnf-list-list.Icon li {
  list-style: none;
  display: inline-flex;
}
.lnf-list-list.Icon li:before {
  content: var(--list-icon-content);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}
/*Custom*/
.lnf-list-list.Custom li {
  list-style: none;
  display: inline-flex;
}
.lnf-list-list.Custom .lnf-custom-icon-li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.lnf-list-list.Custom {
  padding-left: 0;
}

.lnf-list-list.Image {
  list-style: none;
}
.lnf-list-list.Image li:before {
  content: "Image";
  margin-right: 10px;
}

div.foo[data-index] {
  --data: attr(data-index);
  --index: var(--data);
}
@media only screen and (max-width: 1000px) {
  #blog {
    [class*="Section_Section"] {
      padding-bottom: 24px !important;

      & > div {
        height: fit-content;
      }
    }

    [class*="Content_content"] h6 {
      text-align: left;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 750px) {
}

@media only screen and (min-width: 1360px) {
  #aboutPhoto {
    & > div {
      background-position: 50% 40%;
    }
  }
}
@media only screen and (min-width: 1860px) {
  #aboutPhoto {
    & > div {
      background-position: 50% 30%;
    }
  }
}
@media only screen and (max-width: 600px) {
}

.headingTitle {
  .main {
    color: var(--black);
  }
}

.cookieBanner {
  // height: 88px;
  * {
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 190%;
    text-decoration: underline;
  }

  @include mq(md) {
    & > div {
      flex-wrap: wrap;
      gap: 32px 0;
      & > div {
        min-width: 100%;
      }
      p {
        width: 100%;
      }
    }
  }
}
