.lnf-input-select {
  border: 1px solid #080808;
  display: inline-block;
  line-height: 1;
  color: #080808;
  position: relative;
  padding: 10px 16px 10px 10px;
  border-radius: 6px;
  transition: 0.2s ease all;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  min-width: fit-content;
  position: relative;
}

.lnf-arrow {
  margin-right: 20px;
}

.lnf-input-select.hover,
.lnf-input-select.focus,
.lnf-input-select.filled {
  border: 1px solid #080808;
}

.lnf-input-select {
  &.hover,
  &.focus,
  &.filled {
    margin-top: 28px;
    .lnf-input-placeholder {
      // top: calc(-16px - 14px);
      // left: 0;
      color: #080808;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
    }
  }
}

.lnf-input-select-input {
  display: none;
}

.lnf-input-select .lnf-input-placeholder {
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  transition: 0.2s ease all;
  color: #080808;
  font-size: 16px;
  background: white;
  font-weight: 500;
}

.lnf-input-select .lnf-input-selected {
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  transition: 0.2s ease all;
  color: #0a0a0a;
  font-size: 16px;
  background: white;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
  display: inline-block;
  box-sizing: border-box;
}

.lnf-select-dropdown {
  display: none;
}

.lnf-select-dropdown[data-visible="true"] {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  border: 2px solid #080808;
  background-color: white;
  border-radius: 16px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  /*transform: translateX(-50%);*/
  z-index: 4;
  width: fit-content;
}

.lnf-select-dropdown-option {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #080808;
  cursor: pointer;
}

.lnf-select-dropdown-option:hover {
  color: #66bf3c;
}

.lnf-select-dropdown-option[data-selected="true"] {
  color: #66bf3c;
}

.lnf-input-select.failed {
  border: 1px solid #e83221;
  margin-bottom: 30px;
}

.lnf-input-select .lnf-input-error {
  position: absolute;
  bottom: -30px;
  left: 0;
  color: #e83221;
}

.lnf-input-select.failed .lnf-input-placeholder {
  color: #e83221;
}

.lnf-input-select[data-disabled="true"] {
  border: 2px solid #a3a3a3;
}
.lnf-input-select[data-disabled="true"] .lnf-input-placeholder {
  color: #a3a3a3 !important;
}
