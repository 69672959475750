@use "sass:map";
$breakpoints: (
  //small
  xxs: 300px,
  xs: 400px,
  s: 650px,
  sm: 800px,
  md: 1000px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1600px,
  xxxl: 2400px,
  //General
  mobile: 380px,
  mobile-plus: 450px,
  mobile-landscape: 576px,
  tablet: 768px,
  tablet-plus: 992px,
  laptop-small: 1200px,
  laptop-medium: 1366px,
  laptop-large: 1440px,
  desktop: 1600px,
  desktop-wide: 1920px,
  desktop-massive: 2560px,
  tv: 4000px,

  //Iphones
  iphone-5: 320px,
  iphone-5-landscape: 568px,
  iphone-6: 375px,
  iphone-6-landscape: 667px,
  iphone-6-plus: 414px,
  iphone-6-plus-landscape: 736px,
  iphone-7: 375px,
  iphone-7-landscape: 667px,
  iphone-7-plus: 414px,
  iphone-7-plus-landscape: 736px,
  iphone-8: 375px,
  iphone-8-landscape: 667px,
  iphone-8-plus: 414px,
  iphone-8-plus-landscape: 736px,
  iphone-x: 375px,
  iphone-x-landscape: 812px,
  iphone-xr: 414px,
  iphone-xr-landscape: 896px,
  iphone-xs-max: 414px,
  iphone-xs-max-landscape: 896px,
  iphone-11: 414px,
  iphone-11-landscape: 896px,
  iphone-11-pro: 375px,
  iphone-11-pro-landscape: 812px,
  iphone-11-pro-max: 414px,
  iphone-11-pro-max-landscape: 896px,
  iphone-se2: 375px,
  iphone-se2-landscape: 667px,
  iphone-12-mini: 375px,
  iphone-12-mini-landscape: 812px,
  iphone-12: 390px,
  iphone-12-landscape: 844px,
  iphone-12-pro: 390px,
  iphone-12-pro-landscape: 844px,
  iphone-12-pro-max: 428px,
  iphone-12-pro-max-landscape: 926px,

  //Ipads
  ipad-mini: 768px,
  ipad-mini-landscape: 1024px,
  ipad: 768px,
  ipad-landscape: 1024px,
  ipad-air: 768px,
  ipad-air-landscape: 1024px,
  ipad-pro-10: 834px,
  ipad-pro-10-landscape: 1112px,
  ipad-pro-11: 834px,
  ipad-pro-11-landscape: 1194px,
  ipad-pro-12: 1024px,
  ipad-pro-12-landscape: 1366px,

  //Pixel
  pixel-2: 411px,
  pixel-2-landscape: 731px,
  pixel-2-xl: 411px,
  pixel-2-xl-landscape: 823px,
  pixel-3: 412px,
  pixel-3-landscape: 824px,
  pixel-3-xl: 411px,
  pixel-3-xl-landscape: 823px,

  //Samsung
  samsung-s9: 360px,
  samsung-s9-landscape: 740px,
  samsung-s10: 360px,
  samsung-s10-landscape: 740px,
  samsung-s10-plus: 412px,
  samsung-s10-plus-landscape: 846px
);

@mixin mq($breakpoint) {
  $breakpoint-size: map.get($breakpoints, $breakpoint);

  @if $breakpoint-size {
    @media (max-width: $breakpoint-size) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin mqx($breakpoint) {
  $breakpoint-size: map.get($breakpoints, $breakpoint);

  @if $breakpoint-size {
    @media (min-width: $breakpoint-size) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}.";
  }
}
