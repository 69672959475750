.inputWrapper.inputWrapper-textarea {
  margin-bottom: 40px;
  height: fit-content;

  textarea {
    color: inherit;
    line-height: 1;
    height: 100% !important;
    line-height: 100% !important;
    min-height: 140px;

    &::placeholder {
      color: white;
      transition: all 0s ease-in-out;
    }
  }

  &.hover,
  &.focus,
  &.filled {
    // margin-top: 58px;
    .input-textarea {
      min-height: 140px;
      resize: vertical;
    }

    .input {
      &::placeholder {
        color: #080808;
        transition: all 0.2s ease-in-out 0.2s;
      }
    }
  }

  .count {
    color: var(--input-label);

    display: block;
    position: absolute;
    bottom: -10px;
    right: 0;
    transform: translateY(100%);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
}
