@use "sass:map";

$normals: (
  // "box-sizing": "border-box",
  // "margin": 0,
  // "padding": 0,
  // "border": "unset",
  // "background-color": "unset",
  // "font-family": "var(--font-main)",
  // "font-size": "unset",
  // "font-weight": "unset",
  // "color": "unset",
  // "line-height": "1",
  // "text-decoration": "none",
  // "text-align": "unset",
);

$variable-map: (
  "c": "color",
  "bg": "background",
  "bgc": "background-color",
  "r": "border-radius",
  "f": "font-family",
  "fs": "font-size",
  "fw": "font-weight",
  "ft": "text-transform",
  "fc": "text-transform",
  "fd": "text-decoration",
  "ls": "letter-spacing",
  "lh": "line-height",
  "m": "margin",
  "mt": "margin-top",
  "mr": "margin-right",
  "mb": "margin-bottom",
  "ml": "margin-left",
  "p": "padding",
  "pt": "padding-top",
  "pr": "padding-right",
  "pb": "padding-bottom",
  "pl": "padding-left",
  "align": "text-align",
  "w": "width",
  "h": "height",
);

@function get-real-name($name) {
  @if map-has-key($variable-map, $name) {
    @return map-get($variable-map, $name);
  } @else {
    @return $name;
  }
}
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@function create-el-variable-name($selector, $name, $mobile) {
  // $cleanedName: str-replace($selector, " ", "-");
  // @return unquote("--test" + $cleanedName + ": 20;");
  $selector: str-replace(str-replace($selector, " ", "_"), ".", "");

  $mb: if($mobile, "mb-", "");
  @return unquote("--" + $selector + "-" + $mb + $name);
}

@function check-value($value) {
  @if str-index(quote(#{$value}), "@") == 1 {
    @return #{"var(--"}#{str-slice($value, 2)}#{")"};
  }
  @return $value;
}

@mixin make-vars($selector, $desktop-vars, $mobile-vars) {
  :root {
    @each $name, $value in $desktop-vars {
      #{create-el-variable-name($selector, $name, false)}: #{check-value(
          $value
        )};
    }
    @each $name, $value in $mobile-vars {
      #{create-el-variable-name($selector, $name, true)}: #{check-value(
          $value
        )};
    }
  }
}

@mixin make-fields($selector, $desktop-vars, $mobile-vars) {
  $desktop-created: $normals;
  $mobile-created: ();

  @each $name, $value in $desktop-vars {
    $real-name: get-real-name($name);
    $desktop-created: map-merge(
      $desktop-created,
      (
        $real-name: var(#{create-el-variable-name($selector, $name, false)}),
      )
    );
  }

  @each $name, $value in $mobile-vars {
    $real-name: get-real-name($name);
    $mobile-created: map-merge(
      $mobile-created,
      (
        $real-name: var(#{create-el-variable-name($selector, $name, true)}),
      )
    );
  }

  #{$selector} {
    @each $key, $value in $desktop-created {
      #{$key}: #{$value};
    }

    @media only screen and (max-width: 600px) {
      @each $key, $value in $mobile-created {
        #{$key}: #{$value};
      }
    }
  }
}

@mixin el($selector, $desktop-vars, $mobile-vars) {
  @include make-vars($selector, $desktop-vars, $mobile-vars);
  @include make-fields($selector, $desktop-vars, $mobile-vars);
}

@function create-var-name($name) {
  // @return unquote("--" + name);
  @return unquote("--" + $name);
}

$palette: (
  100: 40%,
  200: 30%,
  300: 20%,
  400: 10%,
  500: 0,
  600: 10%,
  700: 20%,
  800: 30%,
  900: 40%,
);
@function modify-color-weight($hex, $weight: 500) {
  $percentage: map.get($palette, $weight);
  @if $weight < 500 {
    @return lighten($hex, $percentage);
  } @else if $weight > 500 {
    @return darken($hex, $percentage);
  } @else {
    @return $hex;
  }
}

@function find-contrast-color($color) {
  $contrast: if(lightness($color) > 80%, black, white);
  @return $contrast;
}

@function invert($color, $amount: 100%) {
  $inverse: change-color($color, $hue: hue($color) + 180);
  @return mix($inverse, $color, $amount);
}

@function create-color-variables($name, $value) {
  $variables: ();
  $variables: addToMap($variables, create-var-name($name), $value);
  $variables: addToMap(
    $variables,
    create-var-name($name + "-contrast"),
    find-contrast-color($value)
  );
  $variables: addToMap(
    $variables,
    create-var-name($name + "-invert"),
    find-contrast-color($value)
  );

  @for $i from 1 through 9 {
    @if $i != 5 {
      $weightI: ($i * 100);
      $weighted-color: modify-color-weight($value, $weightI);
      $inverted-color: invert($weighted-color, 100%);
      $contrast-color: find-contrast-color($weighted-color);
      $weight-name: $name + "-" + $weightI;
      $variables: addToMap(
        $variables,
        create-var-name($weight-name),
        $weighted-color
      );
      $variables: addToMap(
        $variables,
        create-var-name($weight-name + "-contrast"),
        $contrast-color
      );
      $variables: addToMap(
        $variables,
        create-var-name($weight-name + "-invert"),
        $inverted-color
      );
    }
  }
  @return $variables;
}

@function addToMap($mapArray, $key, $value) {
  $mapArray: map.merge(
    $mapArray,
    (
      $key: $value,
    )
  );

  @return $mapArray;
}

@mixin printMap($map) {
  @each $name, $value in $map {
    #{$name}: $value;
  }
}

@mixin let($name, $value) {
  #{create-var-name($name)}: $value;
}

@mixin color($name, $value) {
  $variables: create-color-variables($name, $value);
  @include printMap($variables);
}

@mixin colors($colors) {
  @each $name, $value in $colors {
    @include color($name, $value);
  }
}

@mixin lets($name, $variantMap) {
  @each $variant, $value in $variantMap {
    @include let($name + "-" + $variant, check-value($value));
  }
}

@mixin vars($map) {
  @each $name, $value in $map {
    @include let($name, check-value($value));
  }
}

@mixin theme(
  $name,
  $color,
  $contrast,
  $primary: "@primary",
  $accent: "@primary"
) {
  $var: check-value($color);
  $varText: check-value($contrast);
  $varPrimary: check-value($primary);
  $varAccent: check-value($accent);

  [data-theme="#{$name}"] {
    --current-bg-color: #{$var};
    --current-bg-accent: var(--off-white);
    --current-text-color: #{$varText};
    --current-primary-color: #{$varPrimary};
    --current-accent-color: #{$varAccent};

    --current-button-tone: #{$var};
    --current-button-contrast: #{$varText};
    --current-button-primary: #{$varPrimary};
    --current-button-accent: #{$varAccent};
    --input-border: var(--current-text-color);
    --input-border-filled: var(--current-text-color);
    --input-text: var(--current-text-color);
    --input-label: var(--current-text-color);
  }
  .#{$name}Color {
    --current-text-color: #{$var};
    color: var(--current-text-color);
  }
  .#{$name}BG {
    --current-bg-color: #{$var};
  }
}
@mixin themeGradient(
  $name,
  $base,
  $color,
  $contrast,
  $primary: "@primary",
  $accent: "@accent"
) {
  $var: check-value($color);
  $varBase: check-value($base);
  $varText: check-value($contrast);
  $varPrimary: check-value($primary);
  $varAccent: check-value($accent);

  @include theme($name, $color, $contrast, $primary, $accent);
  [data-theme="#{$name}"] {
    --current-button-tone: #{$varBase};
    --current-button-contrast: #{$varText};
    --current-button-primary: #{$varPrimary};
    --current-button-accent: #{$varAccent};
  }
  .#{$name}Color,
  .#{$name} {
    background: #{$var};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
