@import "responsive";
@import "flex";
@import "text";

//write a mixin for capping number of charectors
@mixin container($max-width: var(--container)) {
  box-sizing: border-box;
  width: 100%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-pd);
  padding-right: var(--container-pd);
}

@mixin bgImage($url, $size: cover, $position: 50% 50%) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: $size;
  background-position: $position;
}

@mixin inset-border($thickness, $color) {
  border: none;
  box-shadow: inset 0 0 $thickness $color;
}

@mixin full() {
  position: absolute;
  inset: 0;
}

@mixin size-ratio($ratio) {
  position: relative;
  &::before {
    content: "";
    display: block;
    padding-top: percentage($ratio);
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
